body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f2f2f2;
  overflow-y: scroll;
  padding-top: 55px;
}

.app-body {
 margin-top: 15px;
}

.navbar {
  background-color: white;
  padding-bottom: 0px;
}

.navbar .menu-name {
  display: none;
}

@media (max-width: 576px) {
  .navbar-collapse {
    position: absolute;
    top: 54px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;
  }
  .navbar-collapse.collapsing {
    height: auto;
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    transition: left 0.3s ease;
    left: -100%;
  }
  .navbar-collapse.show {
    left: 0;
    -webkit-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    -moz-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
    background-color: white;
  }

  .navbar .menu-name {
    display: block;
    font-weight: bold;
  }
}

form.login {
  margin-top: 20px;
}

.nav-link {
  color: black !important;
}

.nav-link.active {
  /* border-bottom: 4px solid; */
  font-weight: bold;
}

.filter .btn-default.selected {
  font-weight: bold;
}

.btn.white {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
  outline: 0 !important;
}

.filter .btn-group {
  display: flex;
}

.btn {
  cursor: pointer;
}

.btn.disabled {
  cursor: default;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default:hover, .btn-default:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
  box-shadow: none;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.filter .row {
  margin-bottom: 10px;
}

.collapse-icon {
  padding-right: 10px;
  padding-left: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.right-border {
  border-style: solid;
  border-width: 0px 1px 0px 0px;
  border-radius: 0px;
  border-color: #d5d5d5;
}

.left-border {
  border-style: solid;
  border-width: 0px 0px 0px 1px;
  border-radius: 0px;
  border-color: #d5d5d5;
}

.job-action-btn:hover, .job-action-btn:active {
  color: black;
  border-color: #f2f2f2 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

/* ---- Grid ---- */
.emi-grid {
  margin-bottom: 10px;
}

.emi-grid .card, .emi-grid .card-header {
  background-color: transparent;
  border: none;
}

.emi-grid .card-header {
  padding: 0px;
}

.emi-grid .card-header h5 {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 0px;
}

.emi-grid .card-body {
  padding: 0px;
}

.emi-grid .grid-rows {
  position: relative;
}

.emi-grid .grid-row.loading {
  opacity: 0.1;
}

.emi-grid .loader {
  background: rgba(0,0,0,0.1);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width:100%;
  height:100%;
  transition:opacity 0.5s ease;
  z-index: -1;
}

.emi-grid .grid-rows .loader.loading {
  opacity: 1;
  z-index: initial;
}

.emi-grid .grid-row {
  width: 100%;
}

.grid-row .grid-cell {
  flex-grow: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 8px;
}

.emi-grid .grid-row.header {
  color: #c1c1c1;
  text-transform: uppercase;
  font-weight: normal;
  border-color: transparent;
}

.emi-grid .grid-row.content {
  background-color: white;
  margin-bottom: 5px;
  min-height: 42px;
  border: 1px solid #d5d5d5;
  padding-right: 2px;
  padding-left: 2px;
}

.emi-grid .grid-noresult {
  margin-bottom: 5px;
}

.emi-grid .grid-row.content.threshold .grid-cell {
  background-color: #DFD15A;
}

.emi-grid .grid-row.threshold {
  border: 1px solid #DFD15A;
}

.emi-grid .grid-row.paused {
  border: 1px solid red;
}

.emi-grid .grid-row.finalized-invoice {
  border: 1px solid #28a745;
}

.emi-grid .grid-button {
  box-shadow: none !important;
  cursor: pointer;
  border: 0px;
  width: 45px;
}

.emi-grid .page-link {
  color: black;
  cursor: pointer;
}

.job-grid .grid-rows .grid-row .grid-cell:first-child {
  margin-left: 0px;
}

.job-grid .grid-progress,
.route-grid .grid-progress {
  height: 4px;
  background-color: #E7E6E6;
  width: 100%;
}

.job-grid .grid-progress .incomplete,
.route-grid .grid-progress .incomplete {
  background-color: #1D7FBE;
  height: 4px;
}

.job-grid .grid-progress .complete,
.route-grid .grid-progress .complete {
  background-color: #59B554;
  height: 4px;
}

.job-row-cell .complete {
  color: #59B554;
}

.emi-grid .grid-row-cell {
  background-color: white;
}

.job-grid .grid-rows div.flex-column.grid-row:nth-child(2) {
  margin-top: 5px;
}

.emi-grid .grid-rows .grid-row.flex-column {
  margin-bottom: 5px;
}

.emi-grid .grid-row-cell button {
  background-color: white !important;
  padding: 5px;
}

.emi-grid .grid-row-cell .grid-cell {
  padding: 0px;
  margin-left: 0px;
}

.emi-grid .threshold .mobile-cell {
  background-color: #DFD15A;
}

.emi-grid .button-cell {
  padding-left: 0px;
}

.emi-grid .grid-row.content .button-cell {
  background-color: transparent !important;
}

.emi-grid .mobile-cell {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.emi-grid .gray {
  font-size: 14px;
  color: gray;
}

.emi-grid .name {
  font-weight: bold;
}

.row-selectable .content {
  cursor: pointer;
}

.emi-grid .grid-sum-row {
  cursor: default;
  font-weight: bold;
  background-color: #133A57 !important;
  color: white !important;
}

.emi-grid .grid-client-row {
  margin: 0px 5px 0px 5px;
}

.emi-grid .grid-client-row .grid-row {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-radius: 0px;
  border-color: #d5d5d5;
  padding: 5px;
}

.letter-filter button {
  color: black;
  cursor: pointer;
}

.letter-filter .active {
  font-weight: bold;
}

.emi-delete-modal .modal-header, .emi-url-hints .card-header {
  padding: 10px;
}

.action-buttons button:not(:last-child) {
  margin-right: 10px;
}

.button-select button {
  margin-right: 15px;
}

.emi-form .form-group {
  margin-bottom: 7px;
}

.emi-form .readonly-field {
  border: 1px solid transparent;
  cursor: pointer;
  background: transparent;
}

.emi-form .readonly-field.hovered {
  border: 1px solid #999999;
  height: 40.19px !important;
}

.emi-form .clickbalancing .readonly-field.hovered {
  height: auto !important;
}

.emi-form label {
  color: #333333;
  font-weight: bold;
}

.emi-collapse-card {
  margin-bottom: 10px;
}

.emi-collapse-card .card-header {
  cursor: pointer;
}

.emi-form .form-card.card,
.emi-form .form-card.card > .card-header,
.emi-form .emi-collapse-card.card,
.emi-form .emi-collapse-card.card > .card-header,
.emi-form .form-card.card > .card-footer {
  border: 0px;
  border-radius: 0px;
  background: transparent;
}

.emi-form .form-card.card > .card-body,
.emi-form .emi-collapse-card.card > .collapse > .card-body,
.emi-form .form-card.card > .card-footer,
.emi-form #projectForm .card-body {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.emi-form #projectForm > .card > .card-body > div > .card > .card-body {
  padding-top: 5px;
}

.emi-form .form-card.card > .card-footer {
  padding-top: 0px;
  padding-bottom: 30px;
} 

.emi-form .form-card.card > .card-header,
.emi-form .emi-collapse-card.card > .card-header {
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #999999;
}

.emi-form .form-card.card > .card-header h4 {
  line-height: 38px;
  margin-bottom: 0px;
}

.emi-form .text-muted {
  font-style: italic;
  text-transform: lowercase;
}

.emi-form .form-row .form-group.fixwidth .input-group {
  align-items: initial;
}

.emi-form .form-row .form-group.fixwidth .input-group .form-control {
  flex-grow: 0;
}

.invalid-feedback {
  display: block;
}
/* EMI-325 causing multi select height problem
.react-select__control {
   height: 38px; 
}
*/
.select-invalid .react-select__control {
  border-color: #dc3545 !important;
}

.input-group>.Select {
  display: flex;
  flex: 1 1 auto;
}

.input-group .Select:not(:last-child) .react-select__control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-control:focus {
  border-color: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.job-add-partner {
  min-height: initial !important;
}

.job-add-partner>span {
  margin: 5px 0 5px 20px;
}

.job-add-partner>span>i {
  margin-right: 10px;
}

.project-button>i {
  margin-right: 0.75rem;
}

.project-summary {
  width: 100%;
  text-align: center;
  border-collapse: separate; 
  border-spacing: 7px;
}

.project-summary th {
  text-transform: uppercase;
  padding: 10px 1px;
}

.project-summary tbody td {
  background-color: white;
  padding: 10px 1px;
}

.project-summary thead th:not(:first-child) {
  background-color: #003366;
  color: white;
}

.project-summary th, .project-summary td:not(:first-child) {
  font-size: 1.5rem;
}

.project-summary th span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
}

.route-variable-btn {
  padding: 0.1rem 0.3rem;
}

.variables-field {
  border: 1px solid transparent;
  padding: 0.375rem 0;
  background: transparent;
}

.card-footer button {
  margin-right: 10px;
}

.custom-file label {
  font-weight: normal;
}

.Select-menu-outer {
  z-index: 3;
}

.pagination-nav {
  display: inline-block;
}

/* Workaround for international phone field */
.intl-tel-input {
  display: table-cell !important;
}
.intl-tel-input .selected-flag {
  z-index: 4 !important;
}
.intl-tel-input .country-list {
  z-index: 5 !important;
}
.input-group .intl-tel-input .form-control {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
  width: auto !important;
}

.card-header h5 {
  margin-bottom: 0px;
}

.invoice-project-name {
  margin-bottom: 0px;
  border: 1px solid rgba(0,0,0,.125) !important;
  border-radius: .25rem !important;
}

.invoice-project-name .card-header {
  cursor: pointer;
  padding: 5px 10px;
  background-color: rgba(0,0,0,.03);
  padding-bottom: 0px;
}

.invoice-project-name .card-header h5 {
  font-size: 1rem;
  border: 0px;
}

.invoice-project-name .list-group-item {
  padding: 5px;
}

.invoice-project-card .card-header {
  cursor: default;
  padding: 5px 5px 5px 10px;
}

.invoice-project-card {
  margin-bottom: 10px;
}

.invoice-project-card .card-body {
  padding: 5px 5px 5px 10px;
}

.invoice-project-card .alert {
  padding: 5px 5px 5px 10px;
  margin-bottom: 5px;
}

.invoice-project-card .project-title {
  display: inline-table;
  padding-top: 4px;
}

.invoice-project-card table {
  margin-bottom: 5px;
}

.invoice-project-card table .right {
  text-align: right;
}

.grid-last-cell {
  padding-right: 8px;
}

.researchnow-target-criteria {
  padding: 5px;
  margin-bottom: 5px;
}

.researchnow-quota-cell-criteria .list-group-item {
  border: 0px;
}

.researchnow-quota-cell-criteria .prodege-target-criteria {
  padding: 2px;
}

.researchnow-quota-cell {
  margin-bottom: 3px;
}

.prodege-target-criteria {
  padding: 5px;
}

.prodege-target-criteria .form-group {
  margin-bottom: 0;
}

.prodege-quota > .card-body {
  padding: 15px;
}

.prodege-quota .form-card .card-body {
  padding: 0;
}

.prodege-quota .emi-form .form-card.card > .card-footer,
.prodege-feasibility .form-card.card > .card-footer {
  padding-bottom: 0;
  padding-top: 10px;
}

.target-quota-cell .input-group-addon {
  padding: 0.25rem 0.5rem;
}

.range-select .Select-arrow-zone {
  width: 45px;
}

.tap-range-icon {
  padding-right: 4px;
}

.qb_transaction_param {
  font-weight: bold;
  color: gray;
  font-style: italic;
}

.btn-group-vertical .range-btn {
  padding: 0.15rem 0.5rem;
	font-size: 8px;
	font-weight: bold;
}

.range-modal-btn {
  margin-left: 10px;
}

.top-margin-10 {
  margin-top: 10px;
}

.tab-refresh-btn {
  float: none;
  margin-left: 10px;
  font-size: inherit;
}

.quotaplan-subtitle {
  font-size: 1rem;
}

.row-checkbox {
  margin: 0;
}

#partnerForm .form-control {
  height: auto;
}

.right-margin-10 {
  margin-right: 10px;
}

table.panelapi-table td {
  padding: 0px;
}

.grayscale {
  filter: grayscale();
}

.panelButton {
  cursor: pointer;
}

.feasibility-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
}

.feasibility-modal {
  width: 300px;
  height: 100px;
  line-height: 100px;
  position: fixed;
  top: 50%; 
  left: 50%;
  margin-top: -100px;
  margin-left: -150px;
  background-color: #f1c40f;
  border-radius: 5px;
  text-align: center;
  z-index: 11; /* 1px higher than the overlay layer */
}

.operator-hint .popover {
  max-width: 476px;
}

.operator-hint .popover-body img {
  width: 450px;
}

.readonly-field {
  height: auto;
}

.connector-details h5 {
  color: #ffc107;
}

.connector-details .card-header {
  border-bottom: 1px solid #ffc107 !important;
}

.form-control-plaintext {
  padding: 0.375rem 0.75rem;
}

.panelist-management > .collapse > .card-body {
  margin-left: 15px;
}

.prescreening-button {
  padding-top: 0px;
  padding-bottom: 0px;
}

.connector-details .badge {
  margin-right: 3px;
}

.grid-sum-row .button-cell i.fa {
  color: #fff;
}

.emi-grid .grid-multiview-row {
  margin: 0px 0px 0px 5px;
}

.emi-grid .grid-multiview-row .grid-row {
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-radius: 0px;
  border-color: #d5d5d5;
  padding: 5px;
}

.partner-quota-summary-cell {
  display: block !important;
}

.route-mobile-cell {
  padding-right: 3px;
}

.multi-status .dropdown-menu {
  min-width: initial;
  padding: 0;
}

.multi-status .dropdown-item {
  padding: 0.375rem 0.75rem;
}

.overflow-visible {
  overflow: visible !important;
}

.accounting-email {
  color: red;
}

.task-notification .badge {
  position: relative;
  top: -16px;
  left: -12px;
  border: 1px solid red;
  border-radius: 50%;
  color: red;
}

#project_minimum .readonly-field {
  white-space: pre-wrap
}

.assignment-tab {
  margin-top: 10px;
}

.bid_ir {
  color: #333333 !important;
}